import React from 'react';
import ReactDOM from 'react-dom';

import GifExpertApp from './GifExpertApp';
import './index.css';


ReactDOM.render(
  <GifExpertApp />,
  document.getElementById('root')
);


// Tarea 1: crear componente GifExpertApp
